import React from "react";
import "../CSS/About.css";
import image1 from "../Assets/about.jpeg";
import image2 from "../Assets/mission2.jpeg";
import director1 from "../Assets/director1.jpg";
import director2 from "../Assets/director2.jpg";
import director3 from "../Assets/director3.jpg";
import director4 from "../Assets/director4.jpg";

function About() {
  window.scrollTo(0, 0);

  return (
    <div className="about-main">
      <div className="about-head">
        <h3 className="about-main-title">About Us</h3>
      </div>

      {/* About - Body Stories */}

      <div className="container">
        <div className="about-body-flex">
          <div className="story-left">
            <h3 className="our-story-title">Our Story</h3>
            <p>
              Santh Exports is rooted to a rich legacy of experience and
              proficiency in the home textile industry. The commitment to
              producing quality textile products was first instilled by its
              founder and visionary, Shri M. Mathalaimuthu Mudaliar, in the year
              1932, in the parent company ‘M. Mathalaimuthu Mudaliar & Sons’.
            </p>
            <p>
              Established in 1993 at Karur, Tamil Nadu, Santh Exports has earned
              a reputation for producing premium-quality textile products at
              highly competitive prices. Our commitment to excellence is
              reflected in our in-house manufacturing facilities, which include
              imported open-end machinery for heavy fabrics. To ensure the
              highest level of quality, we have instituted our own dyeing,
              weaving, and printing units equipped with state-of-the-art
              technology.
            </p>
            <p>
              Our profound comprehension of global market trends combined with
              impeccable designs and ultra-advanced capabilities enables us to
              serve the extensive requirements of customers worldwide. Our team
              of expert professionals with skillful artisanship, has made a
              lasting impression on leading brands in Europe and America. Our
              current clientele comprises of prominent retailers and importers
              throughout USA, UK, Netherlands, Germany and France.
            </p>
          </div>
          <div className="story-right">
            <img alt="Images" src={image1}></img>
          </div>
        </div>
        <div className="about-body-flex">
          <div className="story-right">
            <img alt="Images" src={image2}></img>
          </div>
          <div className="story-left">
            <h3 className="our-story-title">Mission</h3>
            <p>
              Our mission is to create high-quality home textile products that
              makes every home feel like a sanctuary. We are committed to
              exercising sustainable production practices, pioneering designs,
              and the finest materials to ensure that every product we
              manufacture meets the utmost standards of excellence and panache.
              Our philosophy centers around cultivating long-term partnerships
              with our clients through immaculate innovation, customer-service
              and timely delivery.
            </p>
          </div>
        </div>

        <h3 className="director-title">Our Directors</h3>

        <div className="flex-main">
          <div className="director-main">
            <div className="front">
              <img alt="Images" className="blur img-wrap" src={director1} />
              <div className="director-content">
                <h3>MR. MAGDALINE JOSEPH BABU</h3>
                <p>Managing Partner</p>
              </div>
            </div>
            <div className="back">
              <p>
                At an early age of 16, Joseph embarked into spearheading the
                family business, MMM & Sons that is renowned in Karur;
                relinquishing his educational journey after his father’s demise.
                Possessing exceptional knowledge and expertise in manufacturing,
                Joseph directs all phases of textile production, from yarn
                spinning to the embellishment of finished merchandises. Joseph
                believes in bespoke quality of design, product and service that
                is meticulously fabricated by a motivated and dedicated team.
              </p>
            </div>
          </div>
          <div className="director-main">
            <div className="front">
              <img alt="Images" className="zoom blur img-wrap" src={director2} />
              <div className="director-content">
                <h3>MR. LEO REX SEBASTIAN</h3>
                <p>Managing Partner</p>
              </div>
            </div>
            <div className="back">
              <p>
                As a BBA graduate from Madurai Kamaraj University, Leo retains
                an inherent aptitude in marketing and team management.
                Frequently travels abroad for business meetings, with a stellar
                record of success in managing diverse clients across Europe,
                Australia, North and South America. Leo gained the foresight to
                commence exports in 1992, extending the family business
                alongside his elder brother, Joseph. The brothers designated the
                company as Santh Exports, in honor of their beloved and diligent
                father.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-main">
          <div className="director-main">
            <div className="front">
              <img alt="Images" className="img-wrap" src={director3} />
              <div className="director-content">
                <h3>Mr. Prasanth Magdaline</h3>
                <p>Managing Partner</p>
              </div>
            </div>
            <div className="back">
              <p>
                Prasanth is the fourth-generation of his family to work for the
                business. Holding an MBA Degree from Newcastle University in
                Singapore, Prasanth is proficient in formulating innovative
                turn-key solutions that promote the organization’s mission. With
                phenomenal management and accounting acumen, Prasanth recognizes
                opportunities for growth and strengthens business-to-client
                relationships. Playing an instrumental role in the advancement
                of production processes that augment efficiency.
              </p>
            </div>
          </div>
          <div className="director-main">
            <div className="front">
              <img alt="Images" className=" director-img" src={director4} />
              <div className="director-content">
                <h3>Mr. Pranesh Antonio</h3>
                <p>Managing Partner</p>
              </div>
            </div>
            <div className="back">
              <p>
                As the company’s youngest fourth-generation member, Pranesh is a
                creative thinker and enthusiastic team leader. Earning an MSc in
                Business Management from Brunel University London, and a B.Des.
                in Fashion Design, Pranesh demonstrates multifaceted
                organizational proficiencies. Keeping a sharp eye for detail,
                Pranesh collaborates in conceptual design, sample development
                and product approvals. Forefronts product forecasting and market
                trajectory by leveraging a strategic-oriented outlook.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
