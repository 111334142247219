import React from "react";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import Home from "./Pages/Home";
import { HashRouter, Routes, Route } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Process from "./Pages/Process";
import Dying from "./Components/Dying";
import StitchingCutting from "./Components/StitchingCutting";
import CheckingPackaging from "./Components/CheckingPackaging";
import Printing from "./Components/Printing";
import Weaving from "./Components/Weaving";
import Collections from "./Pages/Collections";
import TableLinen from "./Components/TableLinen";
import KitchenLinen from "./Components/KitchenLinen";
import LivingLinen from "./Components/LivingLinen";
import Certifications from "./Pages/Certifications";
import Kids from "./Components/Kids";
// import Nav from "./Layout/Nav";

function Main() {
  return (
    <>
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/process" element={<Process />} />
          <Route path="/dying" element={<Dying />} />
          <Route path="/weaving" element={<Weaving />} />
          <Route path="/stitching" element={<StitchingCutting />} />
          <Route path="/checking" element={<CheckingPackaging />} />
          <Route path="/printing" element={<Printing />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/tablelinen" element={<TableLinen />} />
          <Route path="/kitchenlinen" element={<KitchenLinen />} />
          <Route path="/livinglinen" element={<LivingLinen />} />
          <Route path="/kids" element={<Kids />} />
          <Route path="/certificates" element={<Certifications />} />
        </Routes>
        <Footer />
      </HashRouter>
    </>
  );
}
export default Main;
