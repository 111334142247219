import React from "react";
import "../CSS/Home.css";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { GiShakingHands } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { MdHighQuality } from "react-icons/md";
import { GiTeamIdea } from "react-icons/gi";
import { ImTarget } from "react-icons/im";
import Carousel from "react-bootstrap/Carousel";
import image1 from "../Assets/Slide/1.jpg";
import image2 from "../Assets/Slide/2.jpg";
import image3 from "../Assets/Slide/3.jpg";
import image5 from "../Assets/about3.jpg";
import { Link } from "react-router-dom";
import video from "../Assets/video/video.mp4";

function Home() {
  const data = [
    {
      icon: <GiShakingHands className="core-value-icon" />,
      title: "Integrity",
      content:
        "We upload the highest standards of integrity in all our dealings, and always conduct ourselves with honesty and transparency. ",
    },
    {
      icon: <MdHighQuality className="core-value-icon" />,
      title: "Quality",
      content:
        "We are dedicated to providing the highest quality products and services to our customers. ",
    },
    {
      icon: <GiTeamIdea className="core-value-icon" />,
      title: "Innovation",
      content:
        "We are committed to continuously improving and innovating our products and services to meet the evolving needs of our customers.",
    },
  ];

  const data2 = [
    {
      icon: <ImTarget className="core-value-icon" />,
      title: "Sustainability",
      content:
        " We prioritize sustainability in all our business practices, from sourcing environmentally-friendly raw materials to reducing waste and emissions in our manufacturing process. ",
    },
    {
      icon: <HiUserGroup className="core-value-icon" />,
      title: "Customer-Focus",
      content:
        "We put our customers at the heart of everything we do, and strive to exceed their expectations through exceptional service, support, and responsiveness.  ",
    },
  ];

  const list = [
    {
      title: "Customization and Flexibility",
      content:
        "We work with you to create a unique design that reflects your style and meets your requirements. We understand that our client’s needs may change, and we are always willing to be flexible and adaptable. Our team of designers and skilled artisans will ensure that your vision is brought to life. ",
    },
    {
      title: "Excellent Expertise",
      content:
        "Our team of experts have generations of experience in home textile manufacturing, holding a deep understanding of the industry. Our knowledge and expertise enable us to provide valuable  insights and guidance to our clients.",
    },
    {
      title: "Fast Turnaround",
      content:
        "We understand that time is of the essence, and we strive to provide fast turnaround times for our clients. Our efficient manufacturing processes and experienced team enable us to deliver products quality without compromising on quality. ",
    },
  ];
  window.scrollTo(0, 0);

  return (
    <div className="home-main">
      {/* Carousel */}
      <Carousel fade>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            style={{ height: "70vh", objectFit: "cover", opacity: 0.9 }}
            src={image2}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className="slide-div">
              <h3 className="slide-title">Welcome to Santh Exports</h3>
              <p className="slide-content">
                your one-stop-manufacturer for all your home textile needs
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            src={image1}
            style={{
              height: "70vh",
              objectFit: "fill",

              opacity: 0.9,
            }}
            alt="Second slide"
          />

          <Carousel.Caption>
            <div className="slide-div">
              <h3 className="slide-title">
                India Exposition Mart Greater Noida - U.P India
              </h3>
              <p className="slide-content">
                Permanent Mart No. C-12/07 (2nd Floor)
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            style={{ height: "70vh", objectFit: "cover", opacity: 0.9 }}
            src={image3}
            alt="Third slide"
          />

          <Carousel.Caption>
            <div className="slide-div">
              <h3 className="slide-title">Karur Showroom</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container home-body">
        <div className="home-second-div">
          <video autoPlay loop muted width="100%">
            <source src={video} type="video/mp4" controls />
          </video>
        </div>
        <div className="home-second-div-two">
          <h3 className="home-sub-title">
            Home Furnishing & Textile Manufacturing Company
          </h3>
          <p className="home-sub-content">
            Santh Exports is a distinguished textile manufacturing company based
            in Tamil Nadu, India, with over 90 years of experience in
            fashioning, fabricating and supplying premium home furnishing
            products to global markets.
          </p>
          <Link to="/about" className="home-btn">
            Discover
            <BsFillArrowRightSquareFill className="home-icon" />
          </Link>
          <hr style={{ width: "30%" }} />
        </div>
      </div>
      {/* Who We are */}

      <div className="who-we-are-container container">
        <div className="who-we-are-body"></div>
        <div className="who-we-are-content">
          <div>
            <h3 className="who-we-are-title">Who We Are</h3>
            <p className="who-we-are-para">
              At our core, we are a family-run business retaining over nine
              decades of expertise in manufacturing home textiles, spanning four
              generations. Throughout this time, we have collaborated with
              distinguished international corporations and worked with renowned
              brands across America, Europe and Australia.
            </p>
            <p className="who-we-are-para">
              Our unwavering commitment to excellence is reflected in every
              product we manufacture, and we take pride in delivering only the
              best. Working diligently with our clients, we understand their
              distinctive needs and provide bespoke solutions that meet their
              specific requirements.
            </p>
            <Link to="/about" className="who-we-are-btn">
              Learn More <BsFillArrowRightSquareFill className="home-icon" />
            </Link>
          </div>
        </div>
      </div>

      {/* Core Values */}
      <div className="core-values-main">
        <div className="core-value-head">
          <h3 className="core-value-main-title">Core Values</h3>
          <p className="core-value-para">
            We hold ourselves accountable to a set of core values that drive
            every aspect of our business. These values are the foundation of our
            commitment to delivering premium home textile products and
            exceptional customer service.
          </p>
        </div>
        <div className="core-value-flex container">
          {data.map((item, index) => (
            <div key={index} className="core-value-card">
              {item.icon}
              <h3 className="core-value-title">{item.title}</h3>
              <p className="core-value-content">{item.content}</p>
            </div>
          ))}
        </div>
        <div className="core-value-flex container">
          {data2.map((item, index) => (
            <div key={index} className="core-value-card">
              {item.icon}
              <h3 className="core-value-title">{item.title}</h3>
              <p className="core-value-content">{item.content}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="container-fluid choose-us-main">
        <h3 className="choose-title">Why Choose Us</h3>
        <div className="choose-us-flex">
          <div className="choose-left">
            <img src={image5}></img>
          </div>
          <div className="choose-right">
            <div className="choose-us-right-flex">
              {list.map((item, index) => (
                <div key={index} className="choose-us-card">
                  <h3 className="choose-card-title">{item.title}</h3>
                  <p className="choose-card-content">{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
