import React, { useRef, useState } from "react";
import logo from "../Assets/logo.jpg";
import "../CSS/Navbar.css";
import { Link } from "react-router-dom";

// Tabler Icons
import { Menu2, X } from "tabler-icons-react";

function Navbar() {

  // useState
  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <div className="navbar-main">
      <div className="navbar-flex">
        <div className="mobile_nav">
          {show
            ?
            <X onClick={handleClick}
              size={28}
              className="menu_icon"
              strokeWidth={2}
              color={'#1a237e'} />
            :
            <Menu2
              onClick={handleClick}
              size={28}
              className="menu_icon"
              strokeWidth={2}
              color={'#1a237e'} />
          }
          <img alt="Images" className="nav-logo2" src={logo}></img>
        </div>
        <ul className={`navbar-main-links ${show ? "" : 'none'}`}>
          <li>
            <Link onClick={handleClick} to="/" className="nav-links">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} to="/about" className="nav-links">
              About
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/collections" className="nav-links">
              Collections &#9662;
            </Link>
            <ul className="dropdown-content">
              <li>
                <Link onClick={handleClick} to="/kitchenlinen">Kitchen Linen</Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/tablelinen">Table Linen</Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/livinglinen">Living Linen</Link>
              </li>

              <li>
                <Link onClick={handleClick} to="kids">Kids</Link>
              </li>
            </ul>
          </li>
          <img alt="Images" className="nav-logo" src={logo}></img>
          <li>
            <Link onClick={handleClick} to="/certificates" className="nav-links">
              Certifications
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/process" className="nav-links">
              Process &#9662;
            </Link>
            <ul className="dropdown-content">
              <li>
                <Link onClick={handleClick} to="/dying">Dyeing</Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/weaving">Weaving</Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/printing">Printing</Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/stitching">Stitching</Link>
              </li>

              <li>
                <Link onClick={handleClick} to="/checking">Checking</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link onClick={handleClick} to="/contact" className="nav-links">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Navbar;
