import React from "react";
import image1 from "../Assets/PackingChecking/1.jpg";
import image2 from "../Assets/PackingChecking/2.jpg";
import image3 from "../Assets/PackingChecking/3.jpg";
import image4 from "../Assets/PackingChecking/4.jpg";

import "../CSS/ProcessSub.css";
import { useEffect, useRef, useState } from "react";
import Process_Component from "./Process_Component";

function CheckingPackaging() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const imagesRef = useRef([]);

  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  useEffect(() => {
    if (shouldAnimate) {
      imagesRef.current.forEach((image, index) => {
        setTimeout(() => {
          image.classNameList.add("animate");
        }, index * 700);
      });
    }
  }, [shouldAnimate]);
  window.scrollTo(0, 0);


  const Data_List = [
    {
      title: "1. Scouring:",
      para: `Checking, in the context of garment manufacturing and quality control, 
      refers to the process of inspecting and verifying the quality, accuracy, and 
      conformity of garments or textile products. It is a crucial step to ensure that 
      the finished items meet the desired standards and specifications. Checking involves
       examining various aspects of the product, including its construction, measurements,
        finishing, and overall appearance. Effective checking ensures that the manufactured 
        garments or textile products meet the required quality standards, conform to the
         specified design and measurements, and satisfy customer expectations. It helps 
         in maintaining consistency, reducing defects, and upholding the reputation and 
         integrity of the brand or manufacturer.`
    },
  ]

  return (
    <div className="process-sub-main">
      <div className=" process-sub-body">
        <h3 className="process-sub-body-title">Checking & Packaging </h3>

        <div className="process-sub-img-flex">
          <img alt="Images" src={image3} ></img>
          <img alt="Images" src={image2} ></img>
          <img alt="Images" src={image1} ></img>
          <img alt="Images" src={image4} ></img>
          <Process_Component title={"checking section"}
            data={Data_List} />
        </div>
      </div>
    </div>
  );
}
export default CheckingPackaging;
