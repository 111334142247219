import React from "react";
import "../CSS/Process.css";
import process from "../Assets/process-main.jpg";
import process_sub from "../Assets/process2.jpg";
import { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import { TiTickOutline } from "react-icons/ti";

function Process() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsAnimated(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  window.scroll(0, 0);

  return (
    <div className="process-main">
      <div className="process-head">
        <img alt="Images" src={process}></img>
      </div>

      <div className="process-head-content">
        <h3 className="process-sub-title">Our Strength</h3>
        {/* <p className="process-sub-content">
          50,000 Sq feet of factory floor Space. In-house Spinning, Dyeing,
          Printing, Weaving, Cutting, Stitching, Finishing and Packing
          facilities. Showroom with extensive product display in Noida, Expo-
          -Mart and Karur. Design studio and Merchandising Team in Noida &
          Karur.
        </p> */}
      </div>
      <div className="process-body container">
        <div className="process-body-left">
          <img alt="Images" src={process_sub}></img>
        </div>
        <div className="process-body-right">
          <h3 className="process-right-title">
            Our Strength : <span>infrastructure</span>
          </h3>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "0.5s" }}
          >
            <TiTick className="process-icon" />
            <p>Design and Sampling Research</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "1s" }}
          >
            <TiTickOutline className="process-icon" />
            <p>Spinning - 12,000 spindles</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "1.5s" }}
          >
            <TiTick className="process-icon" />
            <p>
              Dyeing - 4 Tons per days, ZLD (Zero Liquid Discharge) and RF Dryer{" "}
            </p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "2s" }}
          >
            <TiTickOutline className="process-icon" />
            <p>Printing - M&R Machine max 6 colours. 10,000 pieces per day</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "2.5s" }}
          >
            <TiTick className="process-icon" />
            <p>Weaving - 12 Automatic Looms and 2 Jacquard Looms</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "3s" }}
          >
            <TiTickOutline className="process-icon" />
            <p>Stitching Machine - 75 Machines </p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "3.5s" }}
          >
            <TiTick className="process-icon" />
            <p>In-house quality control and testing labs</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "4s" }}
          >
            <TiTick className="process-icon" />
            <p>Pressing, Packaging and Shipping</p>
          </div>
          <div
            className={`process-body-sub-div ${isAnimated ? "animated" : ""}`}
            style={{ animationDelay: "4.5s" }}
          >
            <TiTick className="process-icon" />
            <p>Showroom with Extensive Product display in Noida expo mart</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Process;
