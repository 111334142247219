import React, { useEffect, useRef, useState } from "react";
import "../CSS/Contact.css";
import contact from "../Assets/Contact.jpg";


import { useDisclosure } from "@mantine/hooks";
import { Center, Modal } from "@mantine/core";

import { FaceIdError, DiscountCheck } from "tabler-icons-react"

import emailjs from '@emailjs/browser';

function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, 1)

  const form = useRef()
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    companyName: '',
    number: '',
    message: ''
  })
  const [errors, setErrors] = useState(false)
  const [openModal, { open, close }] = useDisclosure(false)
  const [modalOpen, setModalOpen] = useState(false)

  const emailSend = (e) => {
    e.preventDefault(e)
    if (userData.name === '' || userData.email === '' || userData.companyName === '' || userData.message === '' || userData.number === '') {
      // alert("Fill All Data")
      setErrors(true)
      setModalOpen(true)
      setTimeout(() => {
        setModalOpen(false)
      }, 3000)
    }
    else {
      emailjs.sendForm('service_xnmbwtt', 'template_pqkudo8', form.current, '4EmzORIDQdWNpQqMb')
        .then((result) => {
          setErrors(false)
          setModalOpen(true)
          setTimeout(() => {
            setModalOpen(false)
          }, 3000)
          setInterval(() => {
            window.location.reload()
          },3050)
        })
    }
  }

  return (
    <div className="contact-main">
      <Modal centered opened={modalOpen} onClose={close} withCloseButton={false}>
        <Center>
          {
            errors ? (
              <FaceIdError size={'15rem'} strokeWidth={1} color="red" />
            ) : (
              <DiscountCheck size={'15rem'} strokeWidth={1} color="Green" />
            )
          }
        </Center>
        <Center> {errors ? (
          <div className="Error-modal">
            Error In Input Data Kindle Fill all input or Input Valid Data
          </div>
        ) : (
          <div className="Result-modal">
            Mail Send SuccessFully
          </div>
        )} </Center>
      </Modal>
      <div className="contact-head">
        <img
          alt="Images"
          src={
            contact
          }
        ></img>
      </div>
      <div className="container contact-flex">
        <div className="contact-body">
          <h3 className="contact-title">Get in touch</h3>
          <p className="contact-sub-title">
            Feel free to reach out us in case of a customised quote or any other
            query.
          </p>
          <form onSubmit={emailSend} ref={form} method='POST'>
            <div className="form-input">
              <input value={userData.name} name="user_name" type="text" placeholder="Full Name" onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
            </div>
            <div className="form-input">
              <input value={userData.email} name="user_email" type="text" placeholder="Email" onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
            </div>
            <div className="form-input">
              <input value={userData.number} name="number" type="text" placeholder="Phone" onChange={(e) => setUserData({ ...userData, number: e.target.value })} />
            </div>
            <div className="form-input">
              <input value={userData.companyName} name="company_name" type="text" placeholder="Company" onChange={(e) => setUserData({ ...userData, companyName: e.target.value })} />
            </div>
            <div>
              <input value={userData.message} name="message" className="message" type="text" placeholder="Message" onChange={(e) => setUserData({ ...userData, message: e.target.value })} />
            </div>
            <button className="submit-btn" >Submit Now</button>
          </form>
        </div>
        <div className="container contact-bottom-flex">
          <div className="contact-btm-div">
            <h3 className="contact-bottom-title">Corporate Office & Factory</h3>
            <p>Santh Exports</p>
            <p>No. 36 Pugalur Road</p>
            <p>Karur - 639 001</p>
            <p>leo@santhexports.com</p>
            <p>91 9843056387</p>
          </div>
          {/* <div className="contact-btm-div"></div> */}
        </div>
      </div>
    </div>
  );
}
export default Contact;
