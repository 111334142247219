import React from "react";
import { useEffect, useState } from "react";
import livinglinen from "../Assets/KitchenLinen/kitchen-main.jpg";
import image1 from "../Assets/KitchenLinen/1.jpg";
import image2 from "../Assets/KitchenLinen/2.jpg";
import image3 from "../Assets/KitchenLinen/3.jpg";
import image4 from "../Assets/KitchenLinen/4.jpg";
import image11 from "../Assets/LivingLinen/11.jpg";
import image12 from "../Assets/LivingLinen/12.jpg";
import image13 from "../Assets/LivingLinen/13.jpg";
import image14 from "../Assets/LivingLinen/14.jpg";
import image15 from "../Assets/LivingLinen/15.jpg";
import image16 from "../Assets/LivingLinen/16.jpg";
import image17 from "../Assets/LivingLinen/17.jpg";
function KitchenLinen() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsAnimated(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="clasification-main">
      <div className="collection-head">
        <img
          alt="Images" src={livinglinen}></img>
      </div>
      <div className="collection-body">
        <p className="collection-top-title">Kitchen Towel</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image1}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.5s" }}
          />
          <img
            alt="Images"
            src={image2}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.1s" }}
          />
          <img
            alt="Images"
            src={image3}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "1.5s" }}
          />
          <img
            alt="Images"
            src={image4}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2s" }}
          />
        </div>
      </div>
      {/* Hot PaD & gloves */}
      <div className="collection-body">
        <p className="collection-top-title">Hot Pad & Gloves</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image14}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2.5s" }}
          />
          <img
            alt="Images"
            src={image15}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3s" }}
          />
          <img
            alt="Images"
            src={image16}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3.5s" }}
          />

          <img
            alt="Images"
            src={image17}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "4s" }}
          />
        </div>
      </div>

      {/* Apron */}
      <div className="collection-body">
        <p className="collection-top-title">Apron</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image11}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "4.5s" }}
          />
          <img
            alt="Images"
            src={image12}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "5s" }}
          />
          <img
            alt="Images"
            src={image13}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "5.5s" }}
          />
        </div>
      </div>
    </div>
  );
}
export default KitchenLinen;
