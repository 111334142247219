import React from 'react'
import "../CSS/Process_Component.css"

// <Mantine UI
import { Center, Grid, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const Process_Component = ({ data, title }) => {


    const Query = useMediaQuery('(max-width:1060px)')
    return (
        <div>
            <Center>
                <Title style={{textAlign:"center",color:" #1a237e"}} order={Query ? 6 : 1}>
                    {title}
                </Title>
            </Center>
            <Grid className='process_component_grid' >
                {
                    data.map((value, index) => {
                        return (
                            <Grid.Col key={index}>
                                <Title style={{color:" #1a237e"}} order={Query ? 6 : 3}>
                                    {value.title}
                                </Title>

                                <Text className='process_component_grid_para' style={{ textAlign: "justify" }}>
                                    {value.para}
                                </Text>
                            </Grid.Col>
                        )
                    })
                }
            </Grid>
        </div>
    )
}

export default Process_Component
