import React from "react";
import image1 from "../Assets/Dying/1.jpg";
import image2 from "../Assets/Dying/2.jpg";
import image3 from "../Assets/Dying/3.jpg";
import image4 from "../Assets/Dying/4.jpg";

import "../CSS/ProcessSub.css";
import { useEffect, useRef, useState } from "react";
import Process_Component from "./Process_Component";

function Dying() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const imagesRef = useRef([]);

  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  useEffect(() => {
    if (shouldAnimate) {
      imagesRef.current.forEach((image, index) => {
        setTimeout(() => {
          image.classNameList.add("animate");
        }, index * 700);
      });
    }
  }, [shouldAnimate]);

  window.scrollTo(0, 0);


  const Data_List = [
    {
      title: "1. Scouring:",
      para: `In the textile industry, scouring refers to the cleaning of fibers, yarns, or fabrics to 
      remove natural impurities such as oils, waxes, dirt, or sizing agents. It prepares the material for 
      subsequent processing steps like dyeing, printing, or finishing. The specific method and chemicals 
      used depend on the fiber type and the desired level of cleanliness..`
    },
    {
      title: "2. Bleaching:",
      para: `Bleaching is a process commonly used in the textile industry to remove natural or unwanted 
      color from fibers or fabrics. It is primarily carried out on cotton, linen, and other cellulosic fibers
       to achieve a clean white or light-colored fabric, which serves as a base for dyeing or printing.`
    },
    {
      title: "3. Dyeing:",
      para: `The dyeing process in the textile industry involves the application of color to fibers, yarns,
      or fabrics to achieve the desired hue, shade, or pattern.
      `
    },
    {
      title: "4. Finishing and Packing:",
      para: `Finishing and packing are essential processes in the textile industry that occur during the 
      manufacturing and processing of fabrics. These processes add value to the textiles, enhance their 
      appearance, improve their performance, and prepare them for distribution and sale.
      `
    },
  ]
  return (
    <div className="process-sub-main">
      <h3 className="process-sub-body-title">Dyeing Section</h3>
      <div className=" process-sub-body">
        <div className="process-sub-img-flex">
          {/* <img alt="Images" src={image1} ref={(el) => (imagesRef.current[0] = el)}></img >
          <img alt="Images" src={image2} ref={(el) => (imagesRef.current[1] = el)}></img>
          <img alt="Images" src={image3} ref={(el) => (imagesRef.current[2] = el)}></img>
          <img alt="Images" src={image4} ref={(el) => (imagesRef.current[3] = el)}></img> */}
          <img src={image1} alt="Images" />
          <img src={image2} alt="Images" />
          <img src={image3} alt="Images" />
          <img src={image4} alt="Images" />
          <Process_Component data={Data_List} title='YARN DYEING & PROCESSING UNIT : 4 tons per day' />
        </div>
      </div>
    </div>
  );
}
export default Dying;
