import React from "react";
import image1 from "../Assets/Printing/1.jpg";
import image2 from "../Assets/Printing/2.jpg";

import "../CSS/ProcessSub.css";
import { useEffect, useRef, useState } from "react";
import Process_Component from "./Process_Component";

function Printing() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const imagesRef = useRef([]);

  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  useEffect(() => {
    if (shouldAnimate) {
      imagesRef.current.forEach((image, index) => {
        setTimeout(() => {
          image.classNameList.add("animate");
        }, index * 700);
      });
    }
  }, [shouldAnimate]);
  window.scrollTo(0, 0);


  const Data_List = [
    {
      title: "1. Scouring:",
      para: `Textile printing refers to the process of applying designs, patterns, or colors 
      onto fabrics or textiles. It is a specialized form of printing that allows for customization
       and decoration of various textile products, including clothing, home furnishings, and 
       accessories. Texle printing can be used for various purposes, such as adding decorative elements, 
       creating unique designs, branding texles with logos or labels, or even reproducing artwork onto 
       fabric. It offers versatility and allows for creative expression in the world of text.`
    },
  ]

  return (
    <div className="process-sub-main">
      <div className=" process-sub-body">
        <h3 className="process-sub-body-title">Printing</h3>

        <div className="process-sub-img-flex">
          <img alt="Images" src={image1} ></img>
          <img alt="Images" src={image2} ></img>
          <Process_Component data={Data_List} title={'Printing Section: 10000 pcs per day'} />
        </div>
      </div>
    </div>
  );
}
export default Printing;
