import React from "react";
import image1 from "../Assets/new_weaving/Weaving1.png";
import image2 from "../Assets/new_weaving/Weaving2.png";
import image3 from "../Assets/new_weaving/Weaving3.png";
import image4 from "../Assets/new_weaving/Weaving4.png";


import "../CSS/ProcessSub.css";
import Process_Component from "./Process_Component";

function Weaving() {
  window.scrollTo(0, 0);

  const Data_List = [
    {
      title: "1. Winding:",
      para: `It involves transferring yarn from large packages, such as cones 
      or bobbins, onto a cylindrical form called a warp beam. The purpose of 
      winding is to create a consistent and even supply of yarn that can be 
      easily fed into the weaving loom.`
    },
    {
      title: "2. Warping:",
      para: `It involves the preparation and creation of the warp yarns that run 
      lengthwise in the fabric. The warp yarns are attached to the weaving 
      loom and held under tension during the weaving process.`
    },
    {
      title: "3. Weaving:",
      para: `Weaving refers to the actual interlacing of the warp and weft yarns to create a fabric structure.
      Weaving is the primary technique used to produce woven fabrics.`
    },

  ]

  return (
    <div className="process-sub-main">
      <div className=" process-sub-body">
        <h3 className="process-sub-body-title">Weaving</h3>
        <div className="process-sub-img-flex">
          <img alt="Images" src={image1}></img>
          <img alt="Images" src={image2}></img>
          <img alt="Images" src={image3}></img>
          <img alt="Images" src={image4}></img>
          <Process_Component data={Data_List} title="WEAVING UNIT : 5000 meters per day" />
        </div>
      </div>
    </div>
  );
}
export default Weaving;
