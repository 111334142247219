import React from "react";
import collection from "../Assets/collection-main.jpg";
import image1 from "../Assets/LivingLinen/11.jpg";
import image2 from "../Assets/LivingLinen/9.jpg";
import image3 from "../Assets/LivingLinen/16.jpg";
import image4 from "../Assets/LivingLinen/8.jpg";
import image5 from "../Assets/KitchenLinen/6.jpg";
import image6 from "../Assets/KitchenLinen/3.jpg";
import image7 from "../Assets/Placemat/3.jpg";
import image8 from "../Assets/Napkins/3.jpg";
import { useEffect, useState } from "react";
import "../CSS/Collection.css";

function Collections() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsAnimated(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  window.scroll(0, 0);
  return (
    <div className="clasification-main">
      <div className="collection-head">
        <img alt="Images" src={collection}></img>
      </div>
      <div className="collection-body">
        <h2 className="collection-main-title">Product Range</h2>
        <div className="collection-img-flex">
          <div className="product-inner-flex">
            <img alt="Images"
              src={image1}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "0.5s" }}
            />
            <div className="h3-text">
              <h3>Apron</h3>
            </div>
          </div>
          <div className="product-inner-flex">
            <img alt="Images"
              src={image2}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "0.1s" }}
            />
            <div className="h3-text">
              <h3>Baby Blanket</h3>
            </div>
          </div>

          <div className="product-inner-flex">
            <img alt="Images"
              src={image3}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "1.5s" }}
            />
            <div className="h3-text">
              <h3>Gloves</h3>
            </div>
          </div>
          <div className="product-inner-flex">
            <img alt="Images"
              src={image4}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "2s" }}
            />
            <div className="h3-text">
              <h3>Cushions</h3>
            </div>
          </div>
        </div>
        <div className="collection-img-flex">
          <div className="product-inner-flex">
            <img alt="Images"
              src={image5}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "2.5s" }}
            />
            <div className="h3-text">
              <h3>Tea Towels</h3>
            </div>
          </div>
          <div className="product-inner-flex">
            <img alt="Images"
              src={image6}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "3.0s" }}
            />
            <div className="h3-text">
              <h3>Kitchen Towels</h3>
            </div>
          </div>

          <div className="product-inner-flex">
            <img alt="Images"
              src={image7}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "3.5s" }}
            />
            <div className="h3-text">
              <h3>Placemat</h3>
            </div>
          </div>
          <div className="product-inner-flex">
            <img alt="Images"
              src={image8}
              className={`collection-img-flex-img ${isAnimated ? "animated" : ""
                }`}
              style={{ animationDelay: "4s" }}
            />
            <div className="h3-text">
              <h3>Napkins</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Collections;
