import React from "react";
import { useEffect, useState } from "react";
import livinglinen from "../Assets/LivingLinen/kid.jpg";
import image1 from "../Assets/Kids/1.jpg";
import image2 from "../Assets/Kids/2.jpg";
import image3 from "../Assets/Kids/3.jpg";
import image4 from "../Assets/Kids/4.jpg";
import image5 from "../Assets/Kids/5.jpg";

function Kids() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsAnimated(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="clasification-main">
      <div className="collection-head">
        <img alt="Images" src={livinglinen}></img>
      </div>
      <div className="collection-body">
        <p className="collection-top-title"> Shaped Pillows</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image1}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.5s" }}
          />
          <img
            alt="Images"
            src={image2}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.1s" }}
          />
          <img
            alt="Images"
            src={image3}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "1.5s" }}
          />
        </div>
      </div>
      <div className="collection-body">
        <p className="collection-top-title">Baby Blankets</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image4}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2.5s" }}
          />
          <img
            alt="Images"
            src={image5}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3s" }}
          />
        </div>
      </div>
    </div>
  );
}
export default Kids;
