import React from "react";
import image1 from "../Assets/Stitching/1.jpg";
import image2 from "../Assets/Stitching/2.jpg";
import image3 from "../Assets/Stitching/3.jpg";
import image4 from "../Assets/Stitching/4.jpg";

import "../CSS/ProcessSub.css";
import { useEffect, useRef, useState } from "react";
import Process_Component from "./Process_Component";

function StitchingCutting() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const imagesRef = useRef([]);

  useEffect(() => {
    setShouldAnimate(true);
  }, []);




  useEffect(() => {
    if (shouldAnimate) {
      imagesRef.current.forEach((image, index) => {
        setTimeout(() => {
          image.classNameList.add("animate");
        }, index * 700);
      });
    }
  }, [shouldAnimate]);


  const Data_List = [
    {
      title: "1. Scouring:",
      para: `Stitching is the process of using a needle and thread or a sewing machine to join fabric 
      pieces together. It is an essential aspect of sewing and plays a vital role in creating garments, 
      accessories, and other textile products. Stitching techniques vary depending on the desired outcome 
      and fabric type. Common stitches include the straight stitch, zigzag stitch, and overlock stitch.
       Straight stitches are used for basic seams, while zigzag stitches are suitable for finishing raw 
       edges and creating stretchable seams. Overlock stitches are commonly used to join, finish, and 
       trim fabric edges simultaneously. Stitching can be done by hand, but sewing machines offer increased
        efficiency and precision. Overall, stitching is crucial for constructing well-made and durable textile items..`
    },
  ]

  return (
    <div className="process-sub-main">
      <div className=" process-sub-body">
        <h3 className="process-sub-body-title">Stitching & Cutting </h3>
        <div className="process-sub-img-flex">
          <img alt="Images" src={image1} ></img>
          <img alt="Images" src={image4}></img>
          <img alt="Images" src={image2} ></img>
          <img alt="Images" src={image3} ></img>
          <Process_Component data={Data_List}
            title={"Stitching Section: 75 machines"} />
        </div>
      </div>
    </div>
  );
}
export default StitchingCutting;
