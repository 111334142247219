import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../Assets/certificates/Picture1.png";
import image2 from "../Assets/certificates/Picture2.png";
import image3 from "../Assets/certificates/Picture3.png";
import image4 from "../Assets/certificates/Picture4.png";
import image5 from "../Assets/certificates/Picture5.png";
import image6 from "../Assets/certificates/Picture6.png";
import image7 from "../Assets/certificates/Picture7.jpg";
import image8 from "../Assets/certificates/Picture8.jpg";
import image9 from "../Assets/certificates/Picture9.jpg";
import image10 from "../Assets/certificates/Picture10.jpg";
import "../CSS/Certification.css";
import award from "../Assets/awards.jpg";
import award1 from "../Assets/awards/1.jpg";
import award2 from "../Assets/awards/2.jpg";
import award3 from "../Assets/awards/3.jpg";
import award4 from "../Assets/awards/4.jpg";
import social1 from "../Assets/social/1.jpg";
import social2 from "../Assets/social/2.jpg";
import social3 from "../Assets/social/3.jpg";
import social4 from "../Assets/social/4.jpg";
import social5 from "../Assets/social/5.jpg";
import social6 from "../Assets/social/6.jpg";
import social7 from "../Assets/social/7.jpg";
import social8 from "../Assets/social/8.jpg";

function Certifications() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="certificate-main">
      {/* Awards Section */}
      <div className="awards-main container">
        <div className="awards-left">
          <h3 className="our-story-title">Awards</h3>

          <p>
            Our design proficiency and excellence have earned us recognition in
            the form of a ‘One Star Export House’ status awarded by the
            Government of India.
          </p>
          <p>
            Santh Exports has been presented with the Certificate of Merit by
            the ‘Handloom Export Promotion Council’ (Ministry of Textile,
            Government of India), for its exceptional export of handloom
            products in the years 1995-96, 1996-97, and 1998- 99.{" "}
          </p>
        </div>
        <div className="awards-right">
          <img alt="Images" src={award1}></img>
          <img alt="Images" src={award2}></img>
          <img alt="Images" src={award3}></img>
          <img alt="Images" src={award4}></img>
        </div>
      </div>
      {/* Social compliance */}
      <div className="compliance-main container">
        <div className="compliance-left">
          <div>
            <img alt="Images" src={social3} />
            <img alt="Images" src={social2} />
            <img alt="Images" src={social3} />
          </div>
          <div>
            <img alt="Images" src={social4} />
            <img alt="Images" src={social5} />
            <img alt="Images" src={social6} />
          </div>
          <div>
            <img alt="Images" src={social7} />
            <img alt="Images" src={social8} />
            <img alt="Images" src={social8} />
          </div>
        </div>
        <div className="compliance-right">
          <h3 className="compliance-main-title">Social Compliance</h3>
          <p>
            Together with providing safe goods to our clients, we also pursue
            additional ethical manufacturing methods such as encouraging safe
            working conditions and being barbarity. When requested, recycled
            materials are used for packaging. By incorporating sustainable
            operations, we have been able to contribute to the creation of a
            better future.
          </p>
          <h3 className="compliance-main-title-two">Quality Compliance</h3>

          <p>
            We assure the incorporation of sustainable measures while making our
            home textiles. Our products are devoid of the usage of any harmful
            chemicals during printing or dyeing. We are REACH compliant and
            thereby ensure adherence to incorporate environmentally safe
            procedures. Our certifications are testimonial to the fact that we
            are not just qualitatively but compliant socially as well. We also
            strongly believe in the conservation of energy. All our
            manufacturing facilities have solar power plants and over 30% of the
            power requirement comes from them.
          </p>
        </div>
      </div>
      {/* Multi Carousal Section */}
      <div>
        <h3 className="certificate-main-title">Certifications</h3>
        <div className="certificate-multi-carousel">
          <Carousel
            responsive={responsive}
            autoplay={true}
            autoplaySpeed={1000}
            infinite={true}
          >
            <div className="carousel-card">
              <img alt="Images" src={image1}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image2}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image3}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image4}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image5}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image6}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image7}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image8}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image9}></img>
            </div>
            <div className="carousel-card">
              <img alt="Images" src={image10}></img>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
export default Certifications;
