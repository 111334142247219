import React from "react";
import "../CSS/Footer.css";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { AiFillGoogleSquare } from "react-icons/ai";

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-top-flex">
        <div className=" footer-flex">
          <div className="footer-left">
            <h3 className="footer-title">About Santh Exports</h3>
            <p className="footer-left-content">
              India’s finest state-of-the art home furnishings manufacturing
              company with close to 7 decades of experience in the international
              markets.
            </p>
          </div>
          <div className="footer-second">
            <h3 className="footer-title">Address</h3>
            <p>Santh Exports</p>
            <p>No. 36 Pugalur Road</p>
            <p>Karur - 639 001</p>
            <p>leo@santhexports.com</p>
            <p>91 9843056387</p>
          </div>
          <div className="footer-third">
            <h3 className="footer-title">Quick Links</h3>
            <ul>
              <li>
                <Link className="footer-link" to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/collections">
                  Collection
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/process">
                  Process
                </Link>
              </li>
              <li>Careers</li>
              <li>
                <Link className="footer-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <h3 className="footer-title">Location</h3>
            <div className="map-footer">
              <iframe
                width="100%"
                height="auto"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Post%20Box%20No:52%20,%20%20No.46,%20New%20Street,%20%20Karur%20-%20639%20001+(Santh%20Exports)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className=" footer-bottom-flex">
        <div className="container footer-btm-flex">
          <div>
            <p className="footer-copyright">
              @2022 Santh Exports. All Rights Reserved.
              <span>
                <a href="http://interdeccaan.in/" target="_blank">
                  Designed by Inter Deccaan Solutions
                </a>
              </span>
            </p>
          </div>
          <div className="footer-social-icons">
            <FaLinkedin className="footer-icon" />
            <AiFillGoogleSquare className="footer-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
