import React from "react";
import "../CSS/Collection.css";
import { useEffect, useState } from "react";
import tablelinen from "../Assets/TableLinen/table-main.jpg";
import image1 from "../Assets/TableLinen/1.jpg";
import image2 from "../Assets/TableLinen/2.jpg";
import image3 from "../Assets/TableLinen/3.jpg";
import image4 from "../Assets/TableLinen/4.jpg";
import image5 from "../Assets/Placemat/1.jpg";
import image6 from "../Assets/Placemat/2.jpg";
import image7 from "../Assets/Placemat/3.jpg";
import image8 from "../Assets/Placemat/4.jpg";
import image9 from "../Assets/Runner/1.jpg";
import image10 from "../Assets/Runner/2.jpg";
import image11 from "../Assets/Runner/3.jpg";
import image12 from "../Assets/Runner/4.jpg";
import image13 from "../Assets/Napkins/1.jpg";
import image14 from "../Assets/Napkins/2.jpg";
import image15 from "../Assets/Napkins/3.jpg";
import image16 from "../Assets/KitchenLinen/5.jpg";
import image17 from "../Assets/KitchenLinen/6.jpg";
import image18 from "../Assets/KitchenLinen/7.jpg";

function TableLinen() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setIsAnimated(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="clasification-main">
      <div className="collection-head">
        <img alt="Images" src={tablelinen}></img>
      </div>
      <div className="collection-body">
        <p className="collection-top-title">Table Cloths</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image1}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.5s" }}
          />
          <img
            alt="Images"
            src={image2}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "0.1s" }}
          />
          <img
            alt="Images"
            src={image3}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "1.5s" }}
          />
          <img
            alt="Images"
            src={image4}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2s" }}
          />
        </div>
      </div>
      <div className="collection-body">
        <p className="collection-top-title">Place Mat</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image5}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2.5s" }}
          />
          <img
            alt="Images"
            src={image6}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3s" }}
          />
          <img
            alt="Images"
            src={image7}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3.5s" }}
          />
          <img
            alt="Images"
            src={image8}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "4s" }}
          />
        </div>
      </div>
      {/* Runner */}
      <div className="collection-body">
        <p className="collection-top-title">Runner</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image9}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "4.5s" }}
          />
          <img
            alt="Images"
            src={image10}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "5s" }}
          />
          <img
            alt="Images"
            src={image11}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "5.5s" }}
          />
          <img
            alt="Images"
            src={image12}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "6s" }}
          />
        </div>
      </div>
      {/* Napkins */}
      <div className="collection-body">
        <p className="collection-top-title">Napkins</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image13}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "6.5s" }}
          />
          <img
            alt="Images"
            src={image14}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "7s" }}
          />
          <img
            alt="Images"
            src={image15}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "7.5s" }}
          />
        </div>
      </div>

      <div className="collection-body">
        <p className="collection-top-title">Tea Towels</p>
        <div className="collection-img-flex">
          <img
            alt="Images"
            src={image16}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "2.5s" }}
          />
          <img
            alt="Images"
            src={image17}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3s" }}
          />
          <img
            alt="Images"
            src={image18}
            className={`collection-img-flex-img ${isAnimated ? "animated" : ""
              }`}
            style={{ animationDelay: "3.5s" }}
          />
        </div>
      </div>
    </div>
  );
}
export default TableLinen;
